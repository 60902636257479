import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import theme from './src/@chakra-ui/gatsby-plugin/theme'
import { ChakraProvider } from '@chakra-ui/react'
import AuthWrapper from './src/AuthWrapper'

export const wrapPageElement = ({ element, props }) => {
	return <AuthWrapper>{element}</AuthWrapper>
}

export const wrapRootElement = ({ element }) => {
	const queryClient = new QueryClient()

	return (
		<ChakraProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				{element}
				<ReactQueryDevtools />
			</QueryClientProvider>
		</ChakraProvider>
	)
}
