const Textarea = {
    variants: {
        filled: {
            h: '90px',
            maxH: '90px',
            minH: '90px',
            color: 'white',
            bg: 'darkGray',
            px: '20px',
            w: '233px',
            minW: '233px',
            maxW: '233px',
            overflow: 'hidden',
            wordBreak: 'break-word',
            rounded: '0',
            outline: 'none !important',
            border: 'none !important',
            boxShadow: 'none',
            fontSize: '40px',
            fontWeight: '900',
            textAlign: 'center',
            lineHeight: '36px',
            textTransform: 'uppercase',
            fontFamily: 'Basetica, Helvetica, Arial, sans-serif !important',
            resize: 'none',
            _focus: { bg: 'darkGray' },
            _hover: { bg: 'darkGray' },
        }

    }
}

export default Textarea
