import * as React from 'react'
import AuthProvider from './hooks/useAuth'
import useWindowHeight from './hooks/useWindowHeight'
import { WindowHeightContext } from './context/windowHeightContext'
import 'firebase/auth'

// bootstrap libs
import './helpers/yupBadString'

const AuthWrapper = ({ children }) => {
	const height = useWindowHeight()
	return (
		<WindowHeightContext.Provider value={height}>
			<AuthProvider>{children}</AuthProvider>
		</WindowHeightContext.Provider>
	)
}

export default AuthWrapper
