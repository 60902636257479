const fontFamily = 'Basetica, Helvetica, Arial, sans-serif !important'

const Styles = {
	global: {
		body: {
			fontFamily,
			bg: 'gold',
			color: 'black'
		},
		// ':root': {
		//   '--chakra-colors-primary-400': mode('gold', 'black')(props),
		//   '--chakra-colors-primary-500': mode('gold', 'black')(props),
		// },
		h1: {
			my: '10px',
			color: 'black',
			textAlign: 'center',
			textTransform: 'uppercase',
			fontSize: '40px !important',
			fontWeight: '900 !important',
			lineHeight: '36px !important',
			letterSpacing: '-0.03em !important',
			fontFamily
		},
		h2: {
			mx: '10px',
			color: 'white',
			textAlign: 'center',
			fontSize: '28px !important',
			fontWeight: '700 !important',
			lineHeight: '39px !important',
			fontFamily
		},
		h3: {
			mx: '10px',
			color: 'white',
			textAlign: 'center',
			fontSize: '24px !important',
			fontWeight: '700 !important',
			lineHeight: '26px !important',
			fontFamily
		},
		h4: {
			mx: '10px',
			color: 'white',
			textAlign: 'center',
			fontWeight: '700 !important',
			fontSize: '21px !important',
			lineHeight: '30px !important',
			fontFamily
		},
		h5: {
			mx: '10px',
			color: 'white',
			textAlign: 'center',
			fontWeight: '500 !important',
			fontSize: '14px !important',
			lineHeight: '18px !important',
			fontFamily
		}
	}
}

export default Styles
