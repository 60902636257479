const Accordion = {
	baseStyle: {
		container: {
			border: 'none !important',
			outline: 'none !important'
		},
		panel: {
			py: '8px',
			mt: '5px',
			px: '10px',
			pb: '0',
			mb: '0',
			mx: '0',
			bg: 'rgba(255, 255, 255, 0.2) !important'
		}
	}
}

export default Accordion
