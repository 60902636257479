import { dlt, get, post, put } from './client'
import { auth } from '../../firebaseConfig'

const getMyGroups = async (userId) => {
	return await get(`groups/${userId}/all`)
}

const getGroup = async (groupId) => {
	return await get(`groups/${groupId}`)
}

const getGroupByEventId = async ({ queryKey }) => {
	const eventId = queryKey[1] || 'undefined'
	return await get(`groups/event/${eventId}`)
}

const getGroupBy7RId = async (eventId) => {
	return await get(`bookings/${eventId}`)
}
const getGroupMembers = async ({ queryKey }) => {
	const group = queryKey[1] || 'undefined'
	return await get(`groups/${group}/members`)
}

const getGroupByRfid = async ({ queryKey }) => {
	return await get(`groups/rfid/${queryKey[1]}`)
}

const createGroup = async (body) => {
	return await post('groups', { body })
}

const joinGroup = async ({ id, teamId }) => {
	let params = {}

	if (teamId) {
		params.teamId = teamId
	}

	return await post(`groups/${id}`, {
		params
	})
}

const leaveGroup = async ({ id }) => {
	return await dlt(`groups/${id}/leave`)
}

const removeUserFromGroup = async ({ id, memberId }) => {
	return await dlt(`groups/${id}/remove`, {
		body: [{ memberId }]
	})
}

const updateGroup = async ({ id, name }) => {
	return await put(`groups/${id}/name`, {
		body: {
			name
		}
	})
}

export {
	getMyGroups,
	getGroup,
	getGroupByRfid,
	createGroup,
	joinGroup,
	leaveGroup,
	getGroupBy7RId,
	removeUserFromGroup,
	updateGroup,
	getGroupMembers,
	getGroupByEventId
}
