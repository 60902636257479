


// Transform values for big screens to dynamic
export const calculateHeightValue = (pxVal) => {
	return `${(pxVal / 4082) * 100}vh`
}
export const calculateWidthValue = (pxVal) => {
	return `${(pxVal / 2296) * 100}vw`
}
