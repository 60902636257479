const Input = {
	variants: {
		filled: {
			field: {
				h: '60px',
				minH: '60px',
				maxH: '60px',
				color: 'white',
				bg: 'darkGray',
				px: '22px',
				rounded: '0',
				outline: 'none !important',
				border: 'none !important',
				boxShadow: 'none',
				fontSize: '18px',
				fontWeight: '500',
				fontFamily: 'Basetica, Helvetica, Arial, sans-serif !important',
				_focus: { bg: 'darkGray' },
				_hover: { bg: 'darkGray' },
				_placeholder: { color: 'gray' }
			}
		}
	}
}

export default Input
