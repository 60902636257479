import * as React from 'react'
import { Container, Flex, Spinner } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const Splash = () => {
	return (
		<Container variant="splash">
			<Container variant="light" display="block">
				<Flex
					direction="column"
					alignItems="center"
					justifyContent="center"
					h="100vh"
				>
					<StaticImage
						width={116}
						height={100}
						alt=""
						src="./images/logo.png"
					/>
					{/*{isLoading && (*/}
					{/*	<Spinner*/}
					{/*		mt="20px"*/}
					{/*		thickness="6px"*/}
					{/*		speed="0.7s"*/}
					{/*		emptyColor="gold"*/}
					{/*		color="darkGray"*/}
					{/*		size="xl"*/}
					{/*	/>*/}
					{/*)}*/}
				</Flex>
			</Container>
		</Container>
	)
}

export default Splash
