import Avatar1 from '../images/avatars/1.png'
import Avatar2 from '../images/avatars/2.png'
import Avatar3 from '../images/avatars/3.png'
import Avatar4 from '../images/avatars/4.png'
import Avatar5 from '../images/avatars/5.png'
import Avatar6 from '../images/avatars/6.png'
import Avatar7 from '../images/avatars/7.png'
import Avatar8 from '../images/avatars/8.png'
import Avatar9 from '../images/avatars/9.png'
import Avatar10 from '../images/avatars/10.png'
import Avatar16 from '../images/avatars/16.png'
import Avatar17 from '../images/avatars/17.png'

const avatars = [
	{ id: '1', path: Avatar1 },
	{ id: '2', path: Avatar2 },
	{ id: '3', path: Avatar3 },
	{ id: '4', path: Avatar4 },
	{ id: '5', path: Avatar5 },
	{ id: '6', path: Avatar9 },
	{ id: '7', path: Avatar7 },
	{ id: '8', path: Avatar16 },
	{ id: '9', path: Avatar8 },
	{ id: '10', path: Avatar17 },
	{ id: '11', path: Avatar10 },
	{ id: '12', path: Avatar6 }
]

const getImageById = (id) =>
	avatars.find((item) => item.id == id)?.path || Avatar1

const printTimedScore = (time) => {
	if (time >= 600) {
		return '1:00.0' // Max score reached
	}

	return (time / 10).toFixed(1)
}

const hasMissingFields = (profile) => {
	return (
		!profile?.name ||
		profile?.name === '<anonymous>' ||
		!profile?.surname ||
		!profile?.nickname ||
		!profile?.acceptedTerms
	)
}

const errorToastOptions = {
	status: 'error',
	isClosable: true,
	position: 'top',
	duration: 4000,
	containerStyle: { marginLeft: { lg: 400, base: 0 } }
}

export {
	hasMissingFields,
	getImageById,
	printTimedScore,
	errorToastOptions,
	avatars
}
