import { addMethod, string } from 'yup'
import Filter from 'bad-words'

const filter = new Filter()

addMethod(string, 'notBadWords', function (anyArgsYouNeed = {}) {
	const { message = 'Please remove what can be a bad word from nicknames, team names or groups' } = anyArgsYouNeed
	return string().test('is-bad-word', message, function (value) {
		return !filter.isProfane(value)
	})
})
