import { getAccessToken } from './auth'

const instance = async (method, url, extras) => {
	const idToken = await getAccessToken(false)
	let fUrl = `${process.env.REACT_APP_API_URL}/${url}`

	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=UTF-8'
	}
	if (!!idToken || !!extras?.token)
		headers.Authorization = !!extras?.token
			? `Bearer ${extras.token}`
			: `Bearer ${idToken}`

	const options = {
		method,
		headers,
		timeout: 30000,
		...extras
	}
	console.log({ options })
	if (!!extras?.params) {
		fUrl += `?${new URLSearchParams(extras.params).toString()}`
	}

	if (!!extras?.body) {
		options.body = JSON.stringify(extras.body)
	}

	const response = await fetch(fUrl, options)
	let resJson
	try {
		resJson = await response.json()
	} catch (e) {
		console.error(`${response.statusText} - ${e.message} | ${fUrl}`)
	}
	if (!response.ok) {
		if (!!resJson.message) {
			throw new Error(`Error: ${resJson.message}`)
		} else {
			throw new Error(
				`Server Error: ${resJson.statusCode} - ${resJson.error}`
			)
		}
	}
	return resJson
}

const get = async (url, extras = {}) => {
	return await instance('GET', url, extras)
}

const post = async (url, extras = {}) => {
	return await instance('POST', url, extras)
}

const put = async (url, extras = {}) => {
	return await instance('PUT', url, extras)
}

const dlt = async (url, extras = {}) => {
	return await instance('DELETE', url, extras)
}

export { get, post, put, dlt }
