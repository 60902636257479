import { extendTheme } from '@chakra-ui/react'
import Button from './button'
import Colors from './colors'
import Accordion from './accordion'
import Container from './container'
import Input from './input'
import Menu from './menu'
import Tabs from './tabs'
import Text from './text'
import Styles from './styles'
import Switch from './switch'
import Textarea from './textarea'

const theme = {
	colors: Colors,
	styles: Styles,
	components: {
		Accordion,
		Button,
		Container,
		Menu,
		Tabs,
		Text,
		Input,
		Switch,
		Textarea
	},
	config: {
		initialColorMode: 'dark',
		useSystemColorMode: false
	}
}

export default extendTheme(theme)
