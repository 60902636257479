import { auth } from '../../firebaseConfig'
import { removeTempProfile } from '../helpers/database'
import {
	createUserWithEmailAndPassword,
	isSignInWithEmailLink,
	sendSignInLinkToEmail,
	signInWithEmailAndPassword,
	signInWithEmailLink
} from 'firebase/auth'
import { post } from './client'

//TODO: clean this up as not needed anymore
const getAccessToken = async (forceRefresh = false) => {
	try {
		return await auth.currentUser?.getIdToken(forceRefresh)
	} catch (e) {
		console.error(e)
	}
}

const handleSignOut = async () => {
	try {
		await auth.signOut()
		removeTempProfile()
	} catch (e) {
		console.error(e)
	}
}

/**
 * Email Link Auth Flow (deprecated)
 */

const sendEmailLink = async (email, url) => {
	await sendSignInLinkToEmail(auth, email, {
		url,
		handleCodeInApp: true
	})
}

const registerUser = async (email) => {
	if (isSignInWithEmailLink(auth, window?.location?.href)) {
		try {
			const { user } = await signInWithEmailLink(
				auth,
				email,
				window?.location?.href
			)
			return { uid: user.uid, email: user.email }
		} catch ({ code, message }) {
			// console.log(email, code, message)
			return null
		}
	}
	return {}
}

/**
 * Email Login Flow (no password)
 */

const signInOrCreateUser = async (email) => {
	try {
		try {
			return await createUserWithEmailAndPassword(
				auth,
				email,
				'fairgame-password'
			)
		} catch (err) {
			if (err?.message?.includes('auth/email-already-in-use')) {
				// User already exists, so try logging in
				return await signInWithEmailAndPassword(
					auth,
					email,
					'fairgame-password'
				)
			}
		}
	} catch (e) {
		throw new Error('Server Error')
	}
}

const getUserByEmail = async (email) => {
	return await post(`users/session`, { body: { email } })
}

export {
	getAccessToken,
	sendEmailLink,
	registerUser,
	handleSignOut,
	signInOrCreateUser,
	getUserByEmail
}
