import {
	calculateHeightValue,
	calculateWidthValue
} from '../../helpers/dimensionTransformer'

const Container = {
	variants: {
		light: ({ actualheight }) => ({
			bg: 'gold',
			// minH: `calc(100vh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)))`,
			minH: `${actualheight}px`,
			maxW: 'container.xl',
			p: '20px',
			pb: '20px',
			display: 'flex',
			justifyContent: 'center'
		}),
		dark: ({ actualheight }) => ({
			bg: 'black',
			minH: `${actualheight}px`,
			maxW: 'container.xl',
			p: '20px',
			display: 'flex'
		}),
		splash: () => ({
			bg: 'gold',
			h: '100vh',
			w: '100%',
			maxW: '100%',
			overflow: 'hidden'
		}),
		note: {
			px: '20px',
			py: '24px',
			my: '20px',
			position: 'relative',
			backgroundSize: '100% auto'
		},
		noteScrollable: {
			pt: '20px',
			h: '220px',
			mb: '10px',
			overflowY: 'scroll',
			position: 'relative',
			backgroundSize: '100% auto'
		},
		modal: {
			pt: { lg: '80px', base: '30px' },
			pb: '60px',
			px: '20px',
			position: 'fixed',
			maxW: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			top: '0',
			bottom: '0',
			left: '0',
			right: '0',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			zIndex: '900'
		},
		modalContent: {
			py: '0',
			px: '20px',
			mt: '20px',
			pb: '40px',
			maxH: { lg: 'calc(100vh - 200px)', base: 'calc(100vh - 90px)' },
			overflowY: 'scroll',
			position: 'relative',
			backgroundSize: '100% auto'
		},
		game: {
			mt: '40px',
			maxW: '400px'
		},
		gameTab: {
			mt: '40px',
			mb: '25px',
			p: '0',
			ml: '0',
			mr: '15px',
			w: '87px',
			minW: '87px',
			cursor: 'pointer',
			_hover: {
				opacity: '1'
			}
		},
		gameTabPL: {
			mt: calculateHeightValue(130),
			mb: calculateHeightValue(80),
			p: '0',
			ml: '0',
			mr: calculateWidthValue(60),
			w: calculateWidthValue(352),
			minW: calculateWidthValue(352),
			cursor: 'pointer',
			_hover: {
				opacity: '1'
			}
		},
		footer: {
			p: '0',
			m: '0',
			display: 'flex',
			justifyContent: 'center',
			w: '100% !important',
			maxW: '100% !important',
			position: 'fixed',
			height: '90px',
			bottom: '0',
			left: '0',
			right: '0'
		},
		leaderboard: {
			w: '335px',
			h: '80px',
			mt: '15px',
			p: '15px',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'rgba(255, 255, 255, 0.3)'
		},
		leaderboardPL: {
			w: calculateWidthValue(1460),
			maxW: calculateWidthValue(1460),
			h: calculateHeightValue(210),
			mt: calculateHeightValue(40),
			py: calculateHeightValue(39),
			pl: calculateWidthValue(140),
			pr: calculateWidthValue(60),
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'rgba(255, 255, 255, 0.3)'
		},
		leaderboardTV: {
			maxW: '100%',
			h: calculateHeightValue(190),
			mt: calculateHeightValue(20),
			p: calculateWidthValue(38),
			display: 'flex',
			alignItems: 'center',
			backgroundColor: 'rgba(255, 255, 255, 0.3)'
		},
		toast: {
			mb: '10px',
			position: 'relative',
			w: '200px',
			h: '44px !important',
			minH: '44px !important',
			backgroundSize: '100% auto'
		},
		playerItem: ({ active }) => ({
			bg: active ? 'gold' : 'darkGray',
			minH: '69px !important',
			h: '69px !important',
			maxH: '69px !important',
			maxW: '100%',
			mb: '12px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			p: '0'
		}),
		playerDropdown: {
			position: 'absolute',
			right: '0px',
			mr: '61px',
			mt: '-90px',
			minH: '55px !important',
			h: '55px !important',
			maxH: '55px !important',
			minW: '200px !important',
			w: '210px !important',
			maxW: '200px !important',
			bgColor: 'white',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			pl: '20px',
			cursor: 'pointer'
		}
	}
}

export default Container
