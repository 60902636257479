const fontFamily = 'Basetica, Helvetica, Arial, sans-serif !important'

const Button = {
	variants: {
		primary: ({ disabled }) => ({
			flex: 1,
			bg: disabled ? 'silver !important' : 'white !important',
			h: '69px !important',
			minH: '69px !important',
			maxH: '69px !important',
			px: '16px',
			textColor: 'black',
			fontSize: '30px',
			fontWeight: '900',
			borderRadius: '100px',
			opacity: '1 !important',
			letterSpacing: '0px',
			fontFamily
		}),
		secondary: ({ disabled }) => ({
			flex: 1,
			bg: disabled ? 'silver' : 'black',
			h: '69px !important',
			minH: '69px !important',
			maxH: '69px !important',
			px: '16px',
			textColor: 'white',
			fontSize: '30px',
			fontWeight: '900',
			borderRadius: '100px',
			opacity: '1 !important',
			letterSpacing: '0px',
			fontFamily
		}),
		secondaryBorder: ({ disabled }) => ({
			flex: 1,
			bg: disabled ? 'silver' : 'black',
			h: '69px !important',
			minH: '69px !important',
			maxH: '69px !important',
			px: '16px',
			textColor: 'white',
			fontSize: '30px',
			fontWeight: '900',
			borderRadius: '100px',
			opacity: '1 !important',
			letterSpacing: '0px',
			border: '2px solid rgba(255, 255, 255, 0.5)',
			fontFamily
		}),
		avatar: ({ choosed }) => ({
			border: choosed ? '4px' : 'none',
			borderColor: 'white',
			p: '0',
			w: '90px',
			h: '90px',
			borderRadius: 'full'
		}),
		medium: {
			bg: 'white',
			h: '49px !important',
			minH: '49px !important',
			maxH: '49px !important',
			px: '30px',
			textColor: 'black',
			fontSize: '24px',
			fontWeight: '900',
			borderRadius: '100px',
			opacity: '1 !important',
			textAlign: 'center',
			textTransform: 'capitalize',
			fontFamily
		},
		link: {
			textColor: 'white',
			textDecoration: 'underline',
			fontSize: '20px',
			fontWeight: '500',
			borderRadius: '100px',
			opacity: '1 !important',
			fontFamily
		},
		text: {
			p: '8px',
			textColor: 'white',
			textDecoration: 'underline',
			fontSize: '20px',
			fontWeight: '500',
			borderRadius: '100px',
			opacity: '1 !important',
			fontFamily
		},
		cancel: {
			mt: '20px',
			p: '8px',
			textColor: 'black',
			textDecoration: 'underline',
			fontSize: '20px',
			fontWeight: '500',
			borderRadius: '100px',
			opacity: '1 !important',
			fontFamily
		},
		selector: {
			bg: 'darkGray',
			px: '0',
			h: '60px',
			w: '100%',
			borderRadius: '0',
			fontWeight: '500',
			fontFamily
		},
		groupItem: {
			flex: 1,
			bg: 'rgba(255, 255, 255, 0.2)',
			h: '81px !important',
			minH: '81px !important',
			maxH: '81px !important',
			px: '16px',
			pt: '12px',
			w: '100%',
			mb: '20px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			textColor: 'white',
			borderRadius: '100px',
			opacity: '1 !important',
			fontFamily
		},
		teamItem: ({ active }) => ({
			flex: 1,
			bg: active ? 'gold' : 'rgba(255, 255, 255, 0.2)',
			w: '100%',
			h: '80px !important',
			minH: '80px !important',
			maxH: '80px !important',
			mb: '20px',
			px: '15px',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: active ? 'space-between' : 'flex-start',
			alignItems: 'center',
			textColor: 'white',
			borderRadius: '0',
			opacity: '1 !important',
			fontFamily
		}),
		square: {
			bg: 'rgba(255, 255, 255, 0.2)',
			px: '20px',
			h: '80px',
			textAlign: 'center',
			borderRadius: '0',
			fontWeight: '900',
			fontSize: '30px',
			fontFamily
		},
		menu: {
			m: '0',
			p: '10px',
			borderRadius: '0',
			outline: 'none !important'
		},
		filter: {
			m: '0',
			pl: '15px',
			pr: '16px',
			py: '0',
			h: '60px',
			border: 'none !important',
			borderRadius: '0',
			bg: 'rgba(255, 255, 255, 0.2) !important',
			outline: '0 !important'
		},
		icon: {
			p: 0,
			m: 0,
			border: 0,
			rounded: 0,
			h: 'auto',
			w: 'auto',
			minW: '10px',
			minH: '10px',
			outline: 'none !important',
			fontFamily
		},
		tinyIcon: {
			p: '4px',
			m: 0,
			border: 0,
			rounded: 0,
			h: '32px',
			w: '32px',
			minW: '32px',
			minH: '32px',
			outline: 'none !important',
			fontFamily
		}
	}
}

export default Button
