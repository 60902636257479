const fontFamily = 'Basetica, Helvetica, Arial, sans-serif !important'

const Tabs = {
	variants: {
		'soft-rounded': {
			tabPanels: {
				w: '100%'
			},
			tablist: {
				w: '262px',
				mx: 'auto',
				my: '0',
				p: '6px',
				bg: 'rgba(255, 255, 255, 0.2) !important',
				borderRadius: '100px'
			},
			tab: {
				h: '56px',
				w: '125px',
				fontWeight: '700',
				fontSize: '21px',
				lineHeight: '29px',
				color: 'white',
				textAlign: 'center',
				fontFamily,
				_selected: {
					color: 'black',
					bg: 'white',
					px: '38px'
				}
			}
		}
	}
}

export default Tabs
