const Switch = {
	baseStyle: {
		track: {
			border: '3px solid black',
			bg: 'silver',
			pl: '3px',
			w: '58px',
			h: '36px'
		},
		thumb: {
			w: '36px',
			h: '36px',
			bg: 'white',
			border: '3px solid black'
		}
	}
}

export default Switch
