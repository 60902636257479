const Menu = {
	baseStyle: {
		list: {
			py: '10px',
			borderRadius: '0',
			border: '0'
		},
		item: {
			pl: '20px',
			pr: '35px',
			py: '8px',
			fontWeight: '900'
		}
	},
	variants: {
		options: {
			button: {
				bg: 'none !important',
				border: 'none !important',
				outline: 'none !important',
				boxShadow: 'none !important'
			},
			list: {
				py: '10px',
				borderRadius: '0',
				border: '0'
			},
			item: {
				pl: '20px',
				pr: '35px',
				py: '8px',
				fontWeight: '900'
			}
		}
	}
}

export default Menu
