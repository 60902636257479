import {
	calculateHeightValue,
	calculateWidthValue
} from '../../helpers/dimensionTransformer'

const fontFamily = 'Basetica, Helvetica, Arial, sans-serif !important'
const fontFamilyBold = 'Basetica, Helvetica, Arial, sans-serif !important'
const fontFamilyBlack = 'Basetica, Helvetica, Arial, sans-serif !important'
const fontFamilyBulb =
	'"Basetica Bulb", Basetica, Helvetica, Arial, sans-serif !important'

const Text = {
	variants: {
		xl: {
			fontWeight: '900',
			fontSize: '40px',
			lineHeight: '36px',
			color: 'black',
			textAlign: 'left',
			letterSpacing: '-0.03em',
			fontFamily: fontFamilyBlack
		},
		xls: {
			fontWeight: '900',
			fontSize: '38px',
			lineHeight: '36px',
			color: 'black',
			textAlign: 'left',
			letterSpacing: '-0.03em',
			fontFamily: fontFamilyBlack
		},
		lgg: {
			fontWeight: '900',
			fontSize: '30px',
			lineHeight: '28px',
			color: 'black',
			textAlign: 'left',
			letterSpacing: '-0.03em',
			fontFamily: fontFamilyBold
		},
		lg: {
			fontWeight: '900',
			fontSize: '20px',
			lineHeight: '23px',
			color: 'black',
			textAlign: 'left',
			letterSpacing: '-0.03em',
			fontFamily: fontFamilyBold
		},
		ml: {
			fontWeight: '500',
			fontSize: '18px',
			lineHeight: '20px',
			color: 'black',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		m: {
			fontWeight: '500',
			fontSize: '16px',
			lineHeight: '23px',
			color: 'black',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		s: {
			fontWeight: '600',
			fontSize: '14px',
			lineHeight: '20px',
			color: 'black',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		xs: {
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '15px',
			color: 'black',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		tip: {
			mt: '10px',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '23px',
			color: 'white',
			textAlign: 'center',
			fontFamily: fontFamily
		},
		error: {
			mt: '10px',
			color: 'pink',
			textAlign: 'center',
			fontWeight: '500 !important',
			fontSize: '16px !important',
			lineHeight: '28px !important',
			fontFamily: fontFamily,
			_firstLetter: {
				textTransform: 'capitalize'
			}
		},
		note: {
			mt: '36px',
			mb: '-6px',
			color: 'black',
			textAlign: 'left',
			fontSize: '20px !important',
			lineHeight: '24px !important',
			fontFamily: 'WindsorBld'
		},
		toast: {
			color: 'black',
			textAlign: 'center',
			fontWeight: '600 !important',
			fontSize: '14px !important',
			lineHeight: '18px !important',
			fontFamily: fontFamily
		},
		titlePL: {
			my: '7.2vh',
			color: 'white',
			textAlign: 'center',
			letterSpacing: '-0.03em !important',
			textTransform: 'uppercase',
			fontSize: '11vw !important',
			fontWeight: '900 !important',
			lineHeight: '1 !important',
			fontFamily: fontFamilyBulb
		},
		titleTV: {
			mt: calculateHeightValue(180),
			mb: calculateHeightValue(90),
			color: 'black',
			textTransform: 'uppercase',
			fontSize: `${calculateWidthValue(140)} !important`,
			fontWeight: '900 !important',
			lineHeight: `${calculateWidthValue(136)} !important`,
			letterSpacing: '-0.03em !important',
			fontFamily
		},
		rank: {
			textAlign: 'center',
			fontWeight: '700 !important',
			fontSize: '21px !important',
			lineHeight: '30px !important',
			fontFamily
		},
		rankPL: {
			textAlign: 'center',
			fontWeight: '700 !important',
			fontSize: `${calculateWidthValue(63)} !important`,
			lineHeight: `${calculateWidthValue(88)} !important`,
			fontFamily
		},

		rankTV: {
			textAlign: 'center',
			fontWeight: '700 !important',
			fontSize: `${calculateWidthValue(56)} !important`,
			lineHeight: `${calculateWidthValue(78)} !important`,
			fontFamily
		},
		ldName: {
			fontWeight: '800',
			fontSize: '18px',
			lineHeight: '25px',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		ldNamePL: {
			fontWeight: '800',
			fontSize: calculateWidthValue(63),
			lineHeight: calculateWidthValue(88),
			textAlign: 'left',
			fontFamily: fontFamily
		},
		ldNameTV: {
			fontWeight: '800',
			fontSize: calculateWidthValue(45),
			lineHeight: calculateWidthValue(63),
			textAlign: 'left',
			fontFamily: fontFamily
		},
		score: {
			fontWeight: '800',
			fontSize: '18px',
			lineHeight: '25px',
			color: 'black',
			textAlign: 'left',
			fontFamily: fontFamily
		},
		scorePL: {
			fontWeight: '800',
			fontSize: calculateWidthValue(63),
			lineHeight: calculateWidthValue(88),
			color: 'black',
			textAlign: 'right',
			fontFamily: fontFamily
		},
		scoreTV: {
			fontWeight: '800',
			fontSize: calculateWidthValue(48),
			lineHeight: calculateWidthValue(67),
			color: 'black',
			textAlign: 'right',
			fontFamily: fontFamily
		},
		namePL: {
			color: 'white',
			textAlign: 'center',
			fontSize: `${calculateWidthValue(106)} !important`,
			fontWeight: '800 !important',
			lineHeight: `${calculateWidthValue(150)} !important`,
			fontFamily
		},
		versusPL: {
			color: 'white',
			textAlign: 'center',
			fontSize: `${calculateWidthValue(63)} !important`,
			lineHeight: `${calculateWidthValue(88)} !important`,
			fontWeight: '800 !important',
			fontFamily
		},
		subtitle: {
			my: '10px',
			color: 'black',
			textAlign: 'center',
			textTransform: 'uppercase',
			fontSize: '30px !important',
			fontWeight: '900 !important',
			lineHeight: '36px !important',
			letterSpacing: '-0.03em !important',
			fontFamily
		},
		game: {
			mt: '12px',
			px: '8px',
			fontWeight: '800',
			fontSize: '14px',
			lineHeight: '20px',
			color: 'white',
			textAlign: 'center',
			fontFamily: fontFamily
		},
		gamePL: {
			mt: calculateHeightValue(48),
			px: calculateWidthValue(10),
			fontWeight: '800',
			fontSize: calculateWidthValue(56),
			lineHeight: calculateWidthValue(50),
			color: 'white',
			textAlign: 'center',
			fontFamily: fontFamily
		},
		placeholder: {
			fontSize: '18px',
			fontWeight: '500',
			color: 'gray',
			fontFamily: 'Basetica, Helvetica, Arial, sans-serif !important'
		},
		date: {
			fontSize: '18px',
			fontWeight: '500',
			color: 'white',
			fontFamily: 'Basetica, Helvetica, Arial, sans-serif !important'
		}
	}
}

export default Text
