import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID
	// emulatorHost: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST // used for auth locally
}

const app = initializeApp(config)
export const auth = getAuth(app)

if (config.emulatorHost) {
	connectAuthEmulator(auth, config.emulatorHost)
}

export default app
